
function Footer() {
    return(
        <>
        <div className="footer w-full p-0 m-0">
            <div className="mxl-content text-center">
                <p>Broadcast Team &copy; All rights reserved 2023 | site made <a href="https://marcexl.com.ar">marcexl.com.ar</a></p>
            </div>
        </div>
        </>
    );
}

export default Footer;